// partnerCreateUpdate.js
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { connect, useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";
import FormField from "./FormField";
import PartnerCreateUpdateRoot from "./partnerCreateUpdateRoot";
// context
import { setOpenPartnerDrawer, useSoftUIController } from "context";
// redux
import { createPosPartner,updatePosPartner,clearSelectedPartnerToEdit,getAdminListPartners } from "@sharecode/common/redux/actions/posActions";
// utils
import { DefaultServiceHandler } from "utils/http-common";

function PartnerCreateUpdate(props) {
  const dispatch = useDispatch();
  const [controller, softDispatch] = useSoftUIController();
  const { openPartnerDrawer } = controller;
  const [selectedSector, setSelectedSector] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [adminPartners, setAdminPartners] = useState([]);
  const [selectedAdminPartner, setSelectedAdminPartner] = useState(null);

  const sectors = [
    { value: "généraliste", label: "Généraliste" },
    { value: "restauration", label: "Restauration" },
    { value: "commerçants", label: "Commerçants" },
    { value: "retail", label: "Retail" },
    { value: "caviste", label: "Caviste" },
    { value: "bijouterie", label: "Bijouterie" },
  ];

  useEffect(() => {
    dispatch(getAdminListPartners())
      .then((response) => {
        const partnerOptions = response?.data?.map(partner => ({
          value: partner.internal_id,
          label: partner.name,
        }));
        setAdminPartners(partnerOptions || []);
      })
      .catch(error => {
        console.error('Error fetching admin partners:', error);
      });
  }, [dispatch]);

  console.log(adminPartners);

useEffect(() => {
    // Populate form fields if editing an existing partner
  if (props.partnerToEdit) {
      console.log('Partner to edit:', props.partnerToEdit);
      formik.setValues({
        name: props.partnerToEdit?.data?.name,
        area: props.partnerToEdit?.data?.area,
      });
      setSelectedSector(sectors.find((sector) => sector.value === props.partnerToEdit?.data?.area));
    }
    // Reset form if not editing (i.e., creating new partner)
    else {
      formik.resetForm();
      setSelectedSector(null);
    }
  }, [props.partnerToEdit]);


  const PartnerSchema = Yup.object({
    name: Yup.string().min(1, "Nom trop court!").max(50, "Nom trop long!").required("Required"),
    area: Yup.string().required("Required"),
  });

const formik = useFormik({
  initialValues: {
    name: "",
    area: "",
    internal_id: null,
  },
  validationSchema: PartnerSchema,
  onSubmit: (values) => {
    if (props.partnerToEdit) {
      // Edit existing partner
      dispatch(updatePosPartner(values.name, values.area, props.partnerToEdit.data.id))
        .then(() => {
          handleClosePartnerDrawer();
          dispatch(DefaultServiceHandler("success", "success", "Partenaire modifié avec succès!"));
          setSearchParams({ ...Object.fromEntries(searchParams), refresh: Date.now() });
        })
        .catch((error) => {
          dispatch(DefaultServiceHandler("error", "error", error));
        });
    } else {
      // Create new partner with internal_id
      const internal_id = selectedAdminPartner ? selectedAdminPartner.value : null;
      dispatch(createPosPartner(values.name, values.area, internal_id))
        .then(() => {
          handleClosePartnerDrawer();
          dispatch(DefaultServiceHandler("success", "success", "Partenaire ajouté avec succès!"));
          setSearchParams({ ...Object.fromEntries(searchParams), refresh: Date.now() });
        })
        .catch((error) => {
          dispatch(DefaultServiceHandler("error", "error", error));
        });
    }
  },
});


const handleClosePartnerDrawer = () => {
  setOpenPartnerDrawer(softDispatch, false);
  formik.resetForm();
  setSelectedSector(null);
  dispatch(clearSelectedPartnerToEdit());
};

  const handleSelectSector = (event) => {
    setSelectedSector(event);
    formik.setFieldValue("area", event.value);
  };

  const handleAdminPartnerChange = (selected) => {
    console.log('Selected admin partner:', selected);
    setSelectedAdminPartner(selected);
    formik.setFieldValue("internal_id", selected ? selected.value : null);
  };

  return (
    <PartnerCreateUpdateRoot variant="permanent" ownerState={{ openPartnerDrawer }}>
      <form onSubmit={formik.handleSubmit}>
        <SuiBox display="flex" justifyContent="space-between" alignItems="baseline" pt={3} pb={0} px={3}>
          <SuiTypography variant="h4" color="primary">
            {props.partnerToEdit ? "Modifier un Partenaire" : "Ajouter un Partenaire"}
          </SuiTypography>
          <Icon
            sx={{
              fontSize: "1.5rem",
              cursor: "pointer",
              mt: 2,
            }}
            onClick={handleClosePartnerDrawer}
          >
            close
          </Icon>
        </SuiBox>
        <Divider />
        <SuiBox mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormField
                type="text"
                id="name"
                name="name"
                label="Nom du partenaire"
                error={formik.touched.name && Boolean(formik.errors.name)}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <SuiTypography variant="caption" color="error" fontWeight="regular">
                {formik.touched.name && formik.errors.name}
              </SuiTypography>
            </Grid>
            <Grid item xs={12}>
              <SuiBox ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Secteur
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                isClearable
                options={sectors}
                name="area"
                onChange={handleSelectSector}
                value={selectedSector}
                error={formik.touched.sector && Boolean(formik.errors.sector)}
              />
              <SuiTypography variant="caption" color="error" fontWeight="regular">
                {formik.touched.sector && formik.errors.sector}
              </SuiTypography>
            </Grid>
                        <Grid item xs={12}>
              <SuiBox ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Sélectionner un partenaire existant (optionnel)
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                isClearable
                options={adminPartners}
                onChange={handleAdminPartnerChange}
                value={selectedAdminPartner}
              />
            </Grid>
            <Grid container spacing={2} justifyContent="space-between" mt={2} ml={0}>
              <Grid item xs={6}>
                <SuiButton variant="gradient" color="error" onClick={handleClosePartnerDrawer}>
                  Annuler
                </SuiButton>
              </Grid>
              <Grid item xs={6}>
                <SuiButton disabled={!formik.isValid} variant="gradient" color="info" type="submit">
                  Valider
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </form>
    </PartnerCreateUpdateRoot>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(PartnerCreateUpdate);
