import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// internal imports
import FormField from "../FormField";
import SuiButton from "components/SuiButton";
import LoadingOverlay from "../LoadingOverlay";
import StoryList from "../OfferStoriesList";
import EmptyPhonePreview from "../EmptyPhonePreview";
import PhonePreview from "../PhonePreview";

// redux actions
import { getUser } from "@sharecode/common/redux/actions/user";
import { deleteAd } from "@sharecode/common/redux/actions/adsActions";

function OfferStoryCreation({
  formData,
  createdStories,
  setCreatedStories,
  changeActiveStep,
  formikProps,
  onToggleForm,
  isStoryFormVisible,
  mode,
  campaignAds,
  onUpdateRemainingBudget,
  deletedStoriesConsumedBudget,
}) {
  const dispatch = useDispatch();
  const cancelTokenSource = useRef();
  const costPerClick = formData.values.costPerClick;
  const budget = formData.values.budget;
  const maxClicksPossible = Math.floor(budget / costPerClick);
  const adjustedRemainingBudget = parseFloat((maxClicksPossible * costPerClick).toFixed(2));
  const remainingBudgetInEditMode = formData.values.campaignRemainingClicks * costPerClick;

  // Extract the data array from campaignAds
  const { data: campaignAdsData } = campaignAds;
  // states management
  const [loading, setLoading] = useState(false); // state to manage loading spinner visibility
  const [showForm, setShowForm] = useState(mode === "edit" ? false : true);
  const [editingStory, setEditingStory] = useState(null); // State to store the story being edited
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [remainingBudget, setRemainingBudget] = useState(adjustedRemainingBudget.toFixed(2));
  const [storyBudget, setStoryBudget] = useState(0);
  const [deletedStories, setDeletedStories] = useState([]);
  const [activeStories, setActiveStories] = useState([]);
  const [activeAllocatedBudget, setActiveAllocatedBudget] = useState(0);
  const [deletedStoryConsumedBudget, setDeletedStoryConsumedBudget] = useState(0);
  const [deletedStoryUnconsumedBudget, setDeletedStoryUnconsumedBudget] = useState(0);

  // Function to generate a unique ID for newly created stories
  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  useEffect(() => {
    if (mode == "create") {
      if (createdStories.length > 0) return setActiveStories(createdStories);
    }

    if (mode === "edit") {
      const initStories = campaignAdsData.map((ad) => ({
        storyName: ad.ads_name,
        visualChoice: ad.media_link,
        redirectURL: ad.promo_link,
        budgetSplit: ad.budget / 100,
        storyId: ad.id,
        clickCurrent: ad.click_current,
        clickLimit: ad.click_limit,
        deletedAt: ad.deleted_at,
      }));

      // Separate initStories into deleted and active stories based on 'deleted_at'
      const deletedAds = initStories.filter((story) => story.deletedAt);
      const activeAds = initStories.filter((story) => !story.deletedAt);

      // Update state for active stories
      setActiveStories(activeAds);
      setDeletedStories(deletedAds);
      setCreatedStories(activeAds);

      // Avoid validation issue by setting the formik values to the first story
      const firstStory = activeAds[0];
      formikProps.setValues({
        ...formData.values,
        storyName: firstStory?.storyName,
        visualChoice: firstStory?.visualChoice,
        redirectURL: firstStory?.redirectURL,
        budgetSplit: firstStory?.budgetSplit,
      });
    }
  }, [campaignAdsData, mode, createdStories]);

  // useEffect to calculate the allocated budget of active stories
  useEffect(() => {
    const activeAllocatedBudget = activeStories.reduce((acc, story) => acc + story.budgetSplit, 0);
    setActiveAllocatedBudget(activeAllocatedBudget);
  }, [activeStories]);

  // useEffect to calculate the remaining budget deducting deletedConsumedBudget and activeAllocatedBudget

  useEffect(() => {
    let remainingBudget =
      (mode === "edit" ? remainingBudgetInEditMode : adjustedRemainingBudget) -
      activeAllocatedBudget -
      deletedStoryConsumedBudget +
      deletedStoryUnconsumedBudget;

    remainingBudget = Math.max(0, remainingBudget); // Ensure remainingBudget is not less than 0

    setRemainingBudget(remainingBudget.toFixed(2));
  }, [deletedStoriesConsumedBudget, activeAllocatedBudget, adjustedRemainingBudget]);

  // useEffect to pass the remaining budget to the parent component when it changes
  useEffect(() => {
    onUpdateRemainingBudget(Number(remainingBudget).toFixed(2));
  }, [remainingBudget, onUpdateRemainingBudget]);

  // dispatch getUser action to get user data for the logo
  useEffect(() => {
    setLoading(true);
    dispatch(
      getUser(
        searchParams.get("page"),
        searchParams.get("size"),
        searchParams.get("search"),
        cancelTokenSource
      )
    )
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  const maxStories = 5; // Maximum number of stories allowed
  const { values, errors, touched, formField } = formData;
  const { storyName, visualChoice, redirectURL, budgetSplit } = formField;
  const {
    storyName: storyNameV,
    visualChoice: visualChoiceV,
    redirectURL: redirectURLV,
    budgetSplit: budgetSplitV,
    costPerClick: costPerClickV,
  } = values;

  function isUrlValid(userInput) {
    var res = userInput.match(/^(ftp|http|https):\/\/[^ "]+$/);
    return res !== null;
  }
  // Determine if a story media has been selected
  const isMediaSelected = visualChoiceV && visualChoiceV !== "";
  // Create a single story object to display in PhonePreview
  const previewStory = isMediaSelected
    ? [
        {
          storyName: storyNameV,
          visualChoice: visualChoiceV,
          type: "image",
        },
      ]
    : [];

  // Toggle between showing the form and the list
  const toggleView = () => {
    const newShowFormValue = !showForm;
    setShowForm(newShowFormValue);
    onToggleForm(newShowFormValue);
  };

  // Function to show the Snackbar with a custom message
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // Function to handle closing the Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleDelete = async () => {
    const index = editingStory?.index; // Get the index of the story being edited
    // If there is no index, just close the form and return early
    if (index === undefined) {
      onToggleForm(false);
      return;
    }

    if (index !== undefined) {
      setLoading(true); // Start loading

      // Extract the story to delete
      const storyToDelete = createdStories[index];

      // Remove the deleted story from both active and created stories states
      const updatedCreatedStories = createdStories.filter((_, i) => i !== index);
      const updatedActiveStories = activeStories.filter((_, i) => i !== index);

      setCreatedStories(updatedCreatedStories);
      setActiveStories(updatedActiveStories);

      // Update UI state as needed
      if (createdStories.length === 0) {
        changeActiveStep(0);
        setShowForm(true);
        onToggleForm(true);
      } else {
        setShowForm(false);
        onToggleForm(false);
      }

      setLoading(false); // Stop loading
    }
  };

  const handleDeleteInEditMode = async () => {
    const index = editingStory?.index;
    if (index === undefined) {
      onToggleForm(false);
      return;
    }

    setLoading(true);

    const storyToDelete = createdStories[index];

    if (storyToDelete.storyId && typeof storyToDelete.storyId === "number") {
      try {
        await dispatch(deleteAd(storyToDelete.storyId));
        const deletedStoryConsumedBudget = storyToDelete.clickCurrent * costPerClick;
        const deletedStoryUnconsumedBudget = storyToDelete.budgetSplit - deletedStoryConsumedBudget;
        setDeletedStoryConsumedBudget(deletedStoryConsumedBudget);
        setDeletedStoryUnconsumedBudget(deletedStoryUnconsumedBudget);
      } catch (error) {
        console.error("Error deleting ad:", error);
        setLoading(false);
        return;
      }
    } else {
    }

    const updatedCreatedStories = createdStories.filter((_, i) => i !== index);
    const updatedActiveStories = activeStories.filter((_, i) => i !== index);

    setCreatedStories(updatedCreatedStories);
    setActiveStories(updatedActiveStories);

    showSnackbar("Story deleted successfully!");

    if (updatedCreatedStories.length === 0) {
      changeActiveStep(0);
      setShowForm(true);
      onToggleForm(true);
    } else {
      setShowForm(false);
      onToggleForm(false);
    }

    setLoading(false);
  };

  // Function to show the form for adding a new story
  const handleAddNewStory = () => {
    setEditingStory(null); // Clear any story being edited
    setShowForm(true); // Show the form for adding a new story
    onToggleForm(true); // Update any external state if needed

    formikProps.setValues({
      ...formData.values,
      storyName: "",
      visualChoice: "",
      redirectURL: "",
      budgetSplit: 0,
    });
  };

  // Function to show the form for editing an existing story
  const handleEditStory = (story, index) => {
    setEditingStory({ ...story, index }); // Set the story being edited
    // console type of storyid whether is string or number

    setShowForm(true);
    onToggleForm(true);

    // Calculate the max budget for the slider
    const maxBudget = story.budgetSplit + remainingBudget;

    // Set the storyBudget state
    setStoryBudget(maxBudget);

    // Update formikProps with the values of the story being edited
    formikProps.setValues({
      ...formData.values,
      storyName: story.storyName,
      visualChoice: story.visualChoice,
      redirectURL: story.redirectURL,
      budgetSplit: story.budgetSplit,
    });

    // Set the storyBudget to the budget of the story being edited
    setStoryBudget(parseFloat(story.budgetSplit) || 0);
  };

  // Modify handleValidate for both add and edit
  const handleValidate = async () => {
    setLoading(true);
    const errors = await formikProps.validateForm(formData.values);

    if (Object.keys(errors).length === 0) {
      const newStory = {
        ...formData.values,
        storyId: editingStory ? createdStories[editingStory.index].storyId : generateUniqueId(),
      };

      let updatedActiveStories = [...activeStories];
      let updatedCreatedStories = [...createdStories];

      if (editingStory) {
        // Replace the existing story in activeStories with the updated values
        updatedActiveStories = updatedActiveStories.map((story) =>
          story.storyId === editingStory.storyId ? newStory : story
        );
      } else {
        // Add the new story to activeStories
        updatedActiveStories.push(newStory);
      }

      // Replace the existing story in createdStories with the updated values
      updatedCreatedStories = updatedActiveStories;

      setActiveStories(updatedActiveStories); // Update the activeStories state
      setCreatedStories(updatedCreatedStories); // Update the createdStories state

      // update the activeAllocatedBudget and the remainingBudget
      const activeAllocatedBudget = updatedActiveStories.reduce(
        (acc, story) => acc + story.budgetSplit,
        0
      );
      setActiveAllocatedBudget(activeAllocatedBudget);
      setEditingStory(null); // Reset editing state
      setShowForm(false); // Hide the form
      onToggleForm(false); // Update any external state if needed

      setLoading(false);
    } else {
      // Handle validation errors
      formikProps.setErrors(errors);
      formikProps.setTouched(
        Object.keys(errors).reduce((acc, key) => ({ ...acc, [key]: true }), {})
      );
      setLoading(false);
    }
  };

  // loading spinner
  if (loading) {
    return <LoadingOverlay message="Veuillez patienter... ajout de la story en cours" />;
  }

  return (
    <SuiBox>
      {/* Show form or story list depending on showForm state */}
      {isStoryFormVisible ? (
        <>
          <Typography variant="h5" gutterBottom>
            Budget de votre campagne à répartir:{" "}
            <span style={{ color: "#2794F9" }}>
              {remainingBudget === 0 && editingStory ? editingStory.budgetSplit : remainingBudget}€
            </span>
          </Typography>
          <Grid container spacing={3} justifyContent="space-between" alignItems="center">
            {/* Form Grid */}
            <Grid item xs={12} md={5}>
              <Grid item xs={12}>
                {/* Story Name Input */}
                <FormField
                  type={storyName.type}
                  label={storyName.label}
                  name={storyName.name}
                  value={storyNameV}
                  placeholder={storyName.placeholder}
                  error={errors.storyName && touched.storyName}
                  success={storyNameV?.length > 0 && !errors.storyName ? true : undefined}
                  description={storyName.description}
                  disabled={mode === "edit" && typeof editingStory?.storyId === "number"}
                />
              </Grid>

              <Grid item xs={12}>
                {/* Visual Choice Input */}
                <FormField
                  type={
                    mode === "edit" && editingStory && typeof editingStory?.storyId === "number"
                      ? "input"
                      : visualChoice.type
                  }
                  label={visualChoice.label}
                  name={visualChoice.name}
                  // value={visualChoiceV}
                  success={
                    mode === "edit" || (visualChoiceV && !errors.visualChoice) ? true : undefined
                  }
                  description={visualChoice.description}
                  error={mode !== "edit" && errors.visualChoice && touched.visualChoice}
                  disabled={mode === "edit" && typeof editingStory?.storyId === "number"}
                />
              </Grid>

              <Grid item xs={12}>
                {/* Redirect URL Input */}
                <FormField
                  type={redirectURL.type}
                  label={redirectURL.label}
                  name={redirectURL.name}
                  value={redirectURLV}
                  placeholder={redirectURL.placeholder}
                  error={errors.redirectURL && touched.redirectURL}
                  success={
                    redirectURLV && !errors.redirectURL && isUrlValid(redirectURLV)
                      ? true
                      : undefined
                  }
                  description={redirectURL.description}
                  disabled={mode === "edit" && typeof editingStory?.storyId === "number"}
                />
              </Grid>
              <Grid item xs={12}>
                {/* Budget Split Slider */}
                <FormField
                  type="budgetSplitSlider"
                  label={budgetSplit.label}
                  name={budgetSplit.name}
                  value={budgetSplitV}
                  error={errors.budgetSplit && touched.budgetSplit}
                  success={budgetSplitV > 0 && !errors.budgetSplit ? true : undefined}
                  description={budgetSplit.description}
                  maxBudget={
                    editingStory
                      ? Math.round(editingStory.budgetSplit * 100) / 100 +
                        Math.round(remainingBudget * 100) / 100
                      : Math.round(remainingBudget * 100) / 100
                  }
                  costPerClick={formData.values.costPerClick}
                />
              </Grid>
            </Grid>

            {/* Phone preview Grid */}
            <Grid item xs={12} md={5} sx={{ display: "flex", justifyContent: "flex-end" }}>
              {/*PhonePreview */}
              {isMediaSelected ? (
                <PhonePreview stories={previewStory} showNavigation={false} mode={mode} />
              ) : (
                <EmptyPhonePreview />
              )}
            </Grid>
          </Grid>
          {/* Action buttons */}
          <Grid container spacing={2} justifyContent="center">
            {activeStories.length > 1 && (
              <Grid item>
                <SuiButton
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={mode === "edit" ? handleDeleteInEditMode : handleDelete}
                >
                  Supprimer story
                </SuiButton>
              </Grid>
            )}
            <Grid item>
              <SuiButton variant="contained" color="success" size="small" onClick={handleValidate}>
                {editingStory ? "Modifier" : "Valider story"}
              </SuiButton>
            </Grid>
          </Grid>
          {/* Snackbar for displaying messages */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </>
      ) : (
        // Story list
        activeStories?.length > 0 && (
          <StoryList
            stories={activeStories}
            remainingStories={maxStories - activeStories.length}
            changeActiveStep={changeActiveStep}
            toggleView={toggleView}
            handleEditStory={handleEditStory}
            handleAddNewStory={handleAddNewStory}
            remainingBudget={remainingBudget}
            setCreatedStories={setCreatedStories}
            mode={mode}
            deletedStoriesConsumedBudget={deletedStoriesConsumedBudget}
            activeAllocatedBudget={activeAllocatedBudget}
          />
        )
      )}
    </SuiBox>
  );
}

// Add PropTypes for type checking
OfferStoryCreation.propTypes = {
  formData: PropTypes.shape({
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
  }).isRequired,
};

export default OfferStoryCreation;
