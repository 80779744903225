// @ts-nocheck
import * as types from '../actions/actionTypes';

const initialState = {
  posPartners: [],
  posBusiness: [],
  loading: false,
  error: null,
  partnerToEdit: null,
  businessToEdit: null,
};

const posReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_POS_PARTNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_POS_PARTNER_SUCCESS:
      return {
        ...state,
        posPartners: [action.payload],
        loading: false,
      };
    case types.CREATE_POS_PARTNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.GET_POS_PARTNERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_POS_PARTNERS_SUCCESS:
      return {
        ...state,
        posPartners: action.payload,
        loading: false,
      };
    case types.GET_POS_PARTNERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.GET_POS_PARTNER_BY_ID_SUCCESS:
      return {
        ...state,
        partnerToEdit: action.payload,
      };
    case types.GET_POS_PARTNER_BY_ID_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case types.DELETE_POS_PARTNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_POS_PARTNER_SUCCESS:
      return {
        ...state,
        // @ts-ignore
        posPartners: action.payload,
        loading: false,
      };
    case types.DELETE_POS_PARTNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.SET_SELECTED_PARTNER_TO_EDIT:
      return {
        ...state,
        partnerToEdit: action.payload,
      };
    case types.CLEAR_SELECTED_PARTNER_TO_EDIT:
      return {
        ...state,
        partnerToEdit: null,
      };
    case types.UPDATE_POS_PARTNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_POS_PARTNER_SUCCESS:
      return {
        ...state,
        posPartners: action.payload,
        loading: false,
      };
    case types.UPDATE_POS_PARTNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.GET_POS_BUSINESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_POS_BUSINESS_SUCCESS:
      return {
        ...state,
        posBusiness: action.payload,
        loading: false,
      };
    case types.GET_POS_BUSINESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.DELETE_POS_BUSINESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_POS_BUSINESS_SUCCESS:
      return {
        ...state,
        posBusiness: action.payload,
        loading: false,
      };
    case types.DELETE_POS_BUSINESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.SET_SELECTED_PARTNER_ID:
      return {
        ...state,
        selectedPartnerId: action.payload,
      };
    case types.CREATE_POS_BUSINESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_POS_BUSINESS_SUCCESS:
      return {
        ...state,
        posBusiness: action.payload,
        loading: false,
      };
    case types.CREATE_POS_BUSINESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.SET_SELECTED_BUSINESS_TO_EDIT:
      return {
        ...state,
        businessToEdit: action.payload,
      };
    case types.CLEAR_SELECTED_BUSINESS_TO_EDIT:
      return {
        ...state,
        businessToEdit: null,
      };
    case types.UPDATE_POS_BUSINESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_POS_BUSINESS_SUCCESS:
      return {
        ...state,
        posBusiness: action.payload,
        loading: false,
      };
    case types.UPDATE_POS_BUSINESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.GET_POS_BUSINESS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_POS_BUSINESS_BY_ID_SUCCESS:
      return {
        ...state,
        businessToEdit: action.payload,
        loading: false,
      };
    case types.SET_SELECTED_PARTNER_ID:
      return {
        ...state,
        selectedPartnerId: action.payload,
      };
    case types.GET_POS_BUSINESS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.GET_ADMIN_LIST_PARTNERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ADMIN_LIST_PARTNERS_SUCCESS:
      return {
        ...state,
        posPartners: action.payload,
        loading: false,
      };
    case types.GET_ADMIN_LIST_PARTNERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default posReducer;
