/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
// @mui material components
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import * as types from "@sharecode/common/redux/actions/actionTypes";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SuiButton from "components/SuiButton";
import BrandCreateUpdate from "examples/Drawers/brandCreateUpdate";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setOpenBrandDrawer } from "context";
import { connect, useDispatch } from "react-redux";
// Images
import { useSearchParams } from "react-router-dom";
import PageListing from "components/PagesListing";
import { useRole } from "routes/ProtectedRoutes";
import { IS_EMPLOYEE_BRAND_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import TableRequest from "./components/TableRequest";
import { useEffect, useState, useRef } from "react";
import { exportBrands, getBrand } from "@sharecode/common/redux/actions/brand";
import { getBrandsByPartner } from "@sharecode/common/redux/actions/posActions";
import { ExportButton } from "components/ExportButton";
import { SizeSelectorFilter } from "components/Filters/SizeSelectorFilter";
import { SearchInputFilter } from "components/Filters/SearchInputFilter";
import { exportTable } from "@sharecode/common/utils/helpers";
import { URL_EXPORT_BRANDS } from "@sharecode/common/services/constantApi";

import axios from "axios";
import { setSnackbar } from "@sharecode/common/redux/actions/snack";
import SuiSelect from "components/SuiSelect";

const BrandList = (props) => {
  const [controller, dispatch] = useSoftUIController();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const { openBrandDrawer } = controller;
  const handleBrandDrawerOpen = () => setOpenBrandDrawer(dispatch, !openBrandDrawer);
  const role = useRole();
  const disp = useDispatch();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [sizeSelected, setSizeSelected] = useState("");
  const cancelTokenSource = useRef();
  const [selectedPos, setSelectedPos] = useState("all");
  const [totalCount, setTotalCount] = useState(0);

  const handleChangeSize = (e) => {
    setSearchParams({
      page: 1,
      size: e.value,
      search: searchParams.get("search") || "",
    });
  };

  const searchCtn = (e) => {
    setSearchParams({
      page: 1,
      size: searchParams.get("size") ? searchParams.get("size") : 10,
      search: e.target.value,
    });
  };

  const handleDropdownChange = (selectedOption) => {
    setSelectedPos(selectedOption.value);
    setSearchParams({
      page: 1,
      size: searchParams.get("size") || 10,
      search: searchParams.get("search") || "",
      pos: selectedOption.value,
    });
  };

  const fetchBrands = () => {
    setLoading(true);
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
    }
    cancelTokenSource.current = axios.CancelToken.source();
    new Promise(async (resolve, reject) => {
      const page = searchParams.get("page");
      const size = searchParams.get("size");
      const search = searchParams.get("search");
      const pos = searchParams.get("pos");

      const action = !pos
        ? getBrand(page, size, search)
        : getBrandsByPartner(page, size, search, pos);

      disp(action)
        .then((response) => {
          resolve(response);
          setBrands(response.results ? response.results : []);
          setTotalCount(response.count || 0);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleExportBrands = () => {
    setLoadingExport(true);

    const keys = ["search"];

    const paramsValues = {};

    keys.forEach((key) => {
      if (searchParams.get(key)) {
        paramsValues[key] = searchParams.get(key);
      }
    });

    exportTable({ url: URL_EXPORT_BRANDS, fileName: "brands.xlsx" }, paramsValues)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        disp(setSnackbar(true, "error", "error", "No entry to export"));
      })
      .finally(() => {
        setLoadingExport(false);
      });
  };

  useEffect(() => {
    setSizeSelected({
      value: searchParams.get("size") || "10",
      label: searchParams.get("size") || "10",
    });
    setSearchInputValue(searchParams.get("search") || "");
    const pos = searchParams.get("pos") || "";
    setSelectedPos(pos);
  }, [searchParams]);

  useEffect(() => {
    fetchBrands();
  }, [window.location.search]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={2}>
          <Card>
            <SuiBox
              p={2}
              gap={2}
              display="flex"
              justifyContent="space-between"
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <SuiBox>
                <SuiTypography variant="h6" fontWeight="medium">
                  Enseignes existantes
                </SuiTypography>
              </SuiBox>
              <SuiBox
                display="flex"
                gap={2}
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-start", md: "center" },
                }}
              >
                {role != IS_EMPLOYEE_BRAND_VALUE ? (
                  <SuiButton variant="gradient" color="info" onClick={handleBrandDrawerOpen}>
                    <Icon>add</Icon> &nbsp; Ajouter une enseigne
                  </SuiButton>
                ) : (
                  ""
                )}
                {/* <ExportButton
                  disabled={props.brand.count === 0}
                  handleExport={handleExportBrands}
                  loading={loadingExport}
                /> */}
              </SuiBox>
            </SuiBox>

            <SuiBox
              p={2}
              gap={2}
              display="flex"
              justifyContent="space-between"
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <SizeSelectorFilter
                sizeValue={sizeSelected}
                handleSizeChange={handleChangeSize}
                totalCount={totalCount}
              />

              <SuiSelect
                isClearable={true}
                options={[
                  { value: "all", label: "All" },
                  { value: "carrepos", label: "Carrepos" },
                  { value: "csi", label: "CSI" },
                  { value: "coqliqo", label: "Coqliqo" },
                ]}
                value={selectedPos ? {
                  value: selectedPos,
                  label: selectedPos.charAt(0).toUpperCase() + selectedPos.slice(1)
                } : null}
                placeholder="Partenaires"
                onChange={(selectedOption) => {
                  if (!selectedOption) {
                    setSelectedPos("");
                    setSearchParams({
                      page: 1,
                      size: searchParams.get("size") || 10,
                      search: searchParams.get("search") || "",
                    });
                  } else {
                    handleDropdownChange(selectedOption);
                  }
                }}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />

              <SearchInputFilter
                placeholder="Recherche par enseigne..."
                handleSearchChange={searchCtn}
                searchInputValue={searchInputValue}
              />


            </SuiBox>

            <TableRequest loading={loading} brands={brands} setBrands={setBrands} />
          </Card>
          <PageListing data={props.brand} />
        </SuiBox>
      </DashboardLayout>
      <BrandCreateUpdate />
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(BrandList);
