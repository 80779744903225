import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";

export function SearchInputFilter({ placeholder, handleSearchChange, searchInputValue }) {
  return (
    <SuiBox width={250} sx={{ flexShrink: 0 }}>
      <SuiInput
        placeholder={placeholder}
        icon={{
          component: "search",
          direction: "right",
        }}
        onChange={(e) => handleSearchChange(e)}
        value={searchInputValue}
        data-cy="InputSearch"
      />
    </SuiBox>
  );
}
