import SuiButton from "components/SuiButton";

import { CircularProgress } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

// theme
import colors from "assets/theme/base/colors";

export function ExportButton({ handleExport, loading, disabled }) {
  return (
    <SuiButton
      sx={{ width: 125, height: 30 }}
      onClick={handleExport}
      variant="gradient"
      color="info"
      disabled={disabled}
    >
      {loading ? (
        <CircularProgress size={20} color="white" />
      ) : (
        <>
          <span>Exporter</span>
          <DownloadIcon sx={{ marginLeft: 1 }} size="48px" />
        </>
      )}
    </SuiButton>
  );
}
