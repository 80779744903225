/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================


* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
import * as React from "react";
import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// redux
import { register } from "@sharecode/common/redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { DefaultServiceHandler } from "utils/http-common";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import Socials from "layouts/authentication/components/Socials";

// Images
import signup from "assets/images/clip-online-business-negotiation.png";

// utils
import checkEmail from "@sharecode/common/utils/validator";
import { checkPassword } from "@sharecode/common/utils/validator";

import { useFormik } from "formik";

// Loading spinner
import { Oval } from "@agney/react-loading";
import { SIGN_IN } from "@sharecode/common/routes/constants";

function SignUp() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [agreement, setAgreement] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSetAgremment = () => {
    setAgreement(!agreement);
  };

  const [values, setValues] = React.useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const validate = (values) => {
    const errors = {};

    if (!values.first_name) {
      errors.first_name = "Requis";
    }

    if (!values.last_name) {
      errors.last_name = "Requis";
    }

    if (!values.email) {
      errors.email = "Requis";
    } else if (!checkEmail(values.email)) {
      errors.email = "Adresse email invalide";
    }

    if (!values.password1) {
      errors.password1 = "Requis";
    } else if (!checkPassword(values.password1)) {
      errors.password1 =
        "Le mot de passe doit contenir au moins 8 caractères, une lettre majuscule, une lettre minuscule, et un chiffre";
    }

    if (!values.password2) {
      errors.password2 = "Requis";
    } else if (values.password2 != values.password1) {
      errors.password2 = "Les mots de passe ne correspondent pas";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password1: "",
      password2: "",
    },
    validate,
    onSubmit: () => {
      if (agreement) {
        setLoading(true);
        dispatch(register(formik.values))
          .then(() => {
            setTimeout(() => {
              dispatch(
                DefaultServiceHandler(
                  "success",
                  "success",
                  "Un email a été envoyé à " +
                    formik.values.email +
                    " avec un lien pour activer votre compte."
                )
              );

              navigate(SIGN_IN);
            }, 500);
          })
          .catch((error) => {
            dispatch(DefaultServiceHandler("error", "error", error.toString()));
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  return (
    <IllustrationLayout
      title="S'inscrire"
      description="Veuillez remplir ce formulaire pour créer un compte"
      color="info"
      illustration={{
        image: signup,
      }}
    >
      <SuiBox component="form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={{ xs: 0, sm: 2 }}>
          <Grid item xs={12} sm={6}>
            <SuiBox mb={formik.touched.first_name && formik.errors.first_name != null ? 0 : 2}>
              <SuiInput
                placeholder="Prénom"
                size="large"
                id="first_name"
                error={formik.touched.first_name && formik.errors.first_name != null}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
                data-cy="FirstNameRegisterInput"
              />
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="caption" color="error" fontWeight="regular">
                {formik.touched.first_name && formik.errors.first_name ? (
                  formik.errors.first_name
                ) : (
                  <></>
                )}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuiBox mb={formik.touched.last_name && formik.errors.last_name != null ? 0 : 2}>
              <SuiInput
                placeholder="Nom de famille"
                size="large"
                id="last_name"
                type="text"
                error={formik.touched.last_name && formik.errors.last_name != null}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.last_name}
                data-cy="LastNameRegisterInput"
              />
              <SuiBox>
                <SuiTypography variant="caption" color="error" fontWeight="regular">
                  {formik.touched.last_name && formik.errors.last_name ? (
                    formik.errors.last_name
                  ) : (
                    <></>
                  )}
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </Grid>
        </Grid>

        <SuiBox mb={formik.touched.email && formik.errors.email != null ? 0 : 2}>
          <SuiInput
            id="email"
            type="email"
            placeholder="Email"
            size="large"
            error={formik.touched.email && formik.errors.email != null}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            data-cy="EmailRegisterInput"
          />

          <SuiBox>
            <SuiTypography variant="caption" color="error" fontWeight="regular">
              {formik.touched.email && formik.errors.email ? formik.errors.email : <></>}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox
          mb={formik.touched.password && formik.errors.password != null ? 0 : 2}
          position="relative"
          alignItems={{ xs: "flex-start", sm: "center" }}
        >
          <SuiInput
            id="password1"
            type={values.showPassword ? "text" : "password"}
            placeholder="Mot de passe"
            icon={{
              direction: "right",
            }}
            size="large"
            error={formik.touched.password1 && formik.errors.password1 != null}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password1}
            data-cy="PasswordRegisterInput"
          />
          <SuiBox mt={0.5} position="absolute" top="0" right="0">
            <IconButton onClick={handleClickShowPassword} color="primary">
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </SuiBox>
          <SuiBox>
            <SuiTypography variant="caption" color="error" fontWeight="regular">
              {formik.touched.password1 && formik.errors.password1 ? (
                formik.errors.password1
              ) : (
                <></>
              )}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox
          mb={formik.touched.password2 && formik.errors.password2 != null ? 0 : 2}
          position="relative"
          alignItems={{ xs: "flex-start", sm: "center" }}
        >
          <SuiInput
            id="password2"
            type={values.showConfirmPassword ? "text" : "password"}
            placeholder="Confirmer le mot de passe"
            icon={{
              direction: "right",
            }}
            size="large"
            error={formik.touched.password2 && formik.errors.password2 != null}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password2}
            data-cy="ConfirmPasswordRegisterInput"
          />

          <SuiBox mt={0.5} position="absolute" top="0" right="0">
            <IconButton onClick={handleClickShowConfirmPassword} color="primary">
              {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </SuiBox>
          <SuiBox>
            <SuiTypography variant="caption" color="error" fontWeight="regular">
              {formik.touched.password2 && formik.errors.password2 ? (
                formik.errors.password2
              ) : (
                <></>
              )}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <Checkbox checked={agreement} onChange={handleSetAgremment} data-cy="CheckBoxConditionsRegister"/>  
          <SuiTypography
            variant="button"
            fontWeight="regular"
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
           &nbsp;&nbsp;J'accepte les&nbsp;
          </SuiTypography>
          <SuiTypography component="a" href="#" variant="button" fontWeight="bold" textGradient>
            <a href="https://ch3ck.fr/mentions-legales/" target="_blank">
              conditions générales
            </a>
          </SuiTypography>
        </SuiBox>
        {agreement ? null : (
          <SuiBox>
            <SuiTypography variant="caption" color="error" fontWeight="regular">
              Vous devez accepter les conditions générales pour continuer
            </SuiTypography>
          </SuiBox>
        )}
        <SuiBox mt={!agreement ? 0 : 4} mb={1}>
          {loading ? (
            <SuiBox textAlign="center">
              <Oval width="30" color="info" />
            </SuiBox>
          ) : (
            <SuiButton variant="gradient" color="info" size="large" fullWidth type="submit" data-cy="ButtonSignUpSubmit">
              S'inscrire
            </SuiButton>
          )}
        </SuiBox>
        {/* <SuiBox mt={2} mb={1} textAlign="center">
          <SuiTypography variant="body2" color="text" fontWeight="regular">
            Or sign up with
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={2}>
          <Socials />
        </SuiBox>
        <Divider /> */}
        <SuiBox mt={3} textAlign="center">
          <SuiTypography variant="button" color="text" fontWeight="regular">
            Vous avez déjà un compte?&nbsp;
            <SuiTypography
              component={Link}
              to={SIGN_IN}
              variant="button"
              color="info"
              fontWeight="bold"
              textGradient
            >
              Connexion
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </IllustrationLayout>
  );
}

export default SignUp;
