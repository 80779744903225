import { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
// Soft UI Dashboard PRO React components
import Table from "examples/Tables/Table";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React context
import { useSearchParams } from "react-router-dom";
import DefaultCell from "../DefaultCell";
import UserCell from "../UserCell";
import { getClients, getUsersByBrand } from "@sharecode/common/redux/actions/listClients";
import { Oval } from "@agney/react-loading";
import moment from "moment";
import "moment/locale/fr";

const TableRequest = (props) => {
  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    new Promise(async (resolve, reject) => {
      const page = searchParams.get("page");
      const size = searchParams.get("size");
      const search = searchParams.get("search");

      dispatch(getUsersByBrand(page, size, search))
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [window.location.search]);

  useEffect(() => {
    if (props.listClients.results && props.listClients.results.length > 0) {
      let objColumns = [
        { name: "Profil client", align: "left" },
        { name: "Email", align: "left" },
        { name: "Première visite", align: "center" },
        { name: "Dernière visite", align: "center" },
        { name: "Panier moyen", align: "center" },
        { name: "Nombre de tickets", align: "center" },
      ];

      setColumns(objColumns);
      let obj;
      setRows(
        props.listClients.results.map((item) => {
          obj = {
            "Profil client": <UserCell name={`${item.first_name} ${item.last_name}`} />,
            Email: <DefaultCell>{item.check_email}</DefaultCell>,
            "Première visite": (
              <DefaultCell>
                {item.date_joined ? moment(item.date_joined).locale("fr").format("LLL") : " "}
              </DefaultCell>
            ),
            "Dernière visite": (
              <DefaultCell>
                {item.last_login ? moment(item.last_login).locale("fr").format("LLL") : " "}
              </DefaultCell>
            ),
            "Panier moyen": <DefaultCell>{item.averagecart} €</DefaultCell>,
            "Nombre de tickets": <DefaultCell>{item.numberoftickets}</DefaultCell>,
          };

          return obj;
        })
      );
    }
  }, [props.listClients.results]);

  return (
    <>
      {loading ? (
        <SuiBox textAlign="center">
          <Oval width="50" color="info" />
        </SuiBox>
      ) : (
        <>
          {columns && rows && columns.length > 0 && rows.length > 0 ? (
            <Table columns={columns} rows={rows} />
          ) : (
            <SuiBox textAlign="center" marginBottom={5}>
              <SuiTypography variant="h6" fontWeight="medium">
                Aucun client trouvé
              </SuiTypography>
            </SuiBox>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(TableRequest);
