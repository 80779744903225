import * as types from './actionTypes';
import PosService from '../../services/PosService';

// create a new partner
export const createPosPartner = (partnerName,partnerArea,partnerKey) => (dispatch) => {
  dispatch({ type: types.CREATE_POS_PARTNER_REQUEST });
  return PosService.createPosPartner(partnerName,partnerArea,partnerKey)
    .then((response) => {
      dispatch({
        type: types.CREATE_POS_PARTNER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: types.CREATE_POS_PARTNER_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    });
};
// get all partners
export const getPosPartners = () => (dispatch) => {
  dispatch({ type: types.GET_POS_PARTNERS_REQUEST });
  return PosService.getPosPartners()
    .then((response) => {
      dispatch({
        type: types.GET_POS_PARTNERS_SUCCESS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: types.GET_POS_PARTNERS_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    });
};
// get a partner by id
export const getPosPartnerById = (partnerId) => (dispatch) => {
  dispatch({ type: types.GET_POS_PARTNER_BY_ID_REQUEST });
  return PosService.getPosPartnerById(partnerId)
    .then((response) => {
      dispatch({
        type: types.GET_POS_PARTNER_BY_ID_SUCCESS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: types.GET_POS_PARTNER_BY_ID_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    });
}
// delete a partner
export const deletePosPartner = (partnerId) => (dispatch) => {
  dispatch({ type: types.DELETE_POS_PARTNER_REQUEST });
  return PosService.deletePosPartner(partnerId)
    .then((response) => {
      dispatch({
        type: types.DELETE_POS_PARTNER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: types.DELETE_POS_PARTNER_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    });
}
  // update a partner
  export const updatePosPartner = (partnerName, partnerSector, partnerId) => (dispatch) => {
    dispatch({ type: types.UPDATE_POS_PARTNER_REQUEST });
    return PosService.updatePosPartner(partnerName, partnerSector, partnerId)
      .then((response) => {
        dispatch({
          type: types.UPDATE_POS_PARTNER_SUCCESS,
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_POS_PARTNER_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      });
  }
// selected partner to edit
export const setSelectedPartnerToEdit = (partner) => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_PARTNER_TO_EDIT,
    payload: partner,
  });
}

export const clearSelectedPartnerToEdit = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_SELECTED_PARTNER_TO_EDIT,
  });
}
// selected business id
export const setSelectedBusinessToEdit = (business) => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_BUSINESS_TO_EDIT,
    payload: business,
  });
}
// clear selected business id
export const clearSelectedBusinessToEdit = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_SELECTED_BUSINESS_TO_EDIT,
  });
}

// delete a business (pos item)
export const deletePosItem = (partnerId,itemId) => (dispatch) => {
  dispatch({ type: types.DELETE_POS_BUSINESS_REQUEST });
  return PosService.deletePosItem(partnerId,itemId)
    .then((response) => {
      dispatch({
        type: types.DELETE_POS_BUSINESS_SUCCESS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: types.DELETE_POS_BUSINESS_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    });
};
// get a business (pos item) based on partner id
export const getPosItem = (partnerId) => (dispatch) => {
  dispatch({ type: types.GET_POS_BUSINESS_REQUEST });
  return PosService.getPosItem(partnerId)
    .then((response) => {
      dispatch({
        type: types.GET_POS_BUSINESS_SUCCESS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: types.GET_POS_BUSINESS_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    });
};
// create a new business (pos item)
export const createPosItem = (partnerId, businessData) => (dispatch) => {
  dispatch({ type: types.CREATE_POS_BUSINESS_REQUEST });
  return PosService.createPosItem(partnerId, businessData)
    .then((response) => {
      dispatch({
        type: types.CREATE_POS_BUSINESS_SUCCESS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: types.CREATE_POS_BUSINESS_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    });
};
// set selected partner id
export const setSelectedPartnerId = (partnerId) => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_PARTNER_ID,
    payload: partnerId,
  });
};

// Update a business (by business_id)
export const updatePosItemById = (partnerId, itemId, businessData) => (dispatch) => {
  dispatch({ type: types.UPDATE_POS_BUSINESS_REQUEST });
  return PosService.updatePosItemById(partnerId, itemId, businessData)
    .then((response) => {
      dispatch({
        type: types.UPDATE_POS_BUSINESS_SUCCESS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_POS_BUSINESS_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    });
};



// get a business by id
export const getPosItemById = (partnerId, itemId) => (dispatch) => {
  dispatch({ type: types.GET_POS_BUSINESS_BY_ID_REQUEST });
  return PosService.getPosItemById(partnerId, itemId)
    .then((response) => {
      dispatch({
        type: types.GET_POS_BUSINESS_BY_ID_SUCCESS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: types.GET_POS_BUSINESS_BY_ID_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    });
};

// get list of partners
export const getAdminListPartners = () => (dispatch) => {
  dispatch({ type: types.GET_ADMIN_LIST_PARTNERS_REQUEST });
  return PosService.getAdminListPartners()
    .then((response) => {
      dispatch({
        type: types.GET_ADMIN_LIST_PARTNERS_SUCCESS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: types.GET_ADMIN_LIST_PARTNERS_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    });
};

// get brands linked to a partner
export const getBrandsByPartner = (page = 1, pageSize = 10, search = '', pos = 'all') => (dispatch) => {
  dispatch({ type: types.GET_BRANDS_BY_PARTNER_REQUEST });
  return PosService.getBrandsByPartner(page, pageSize, search, pos)
    .then((response) => {
      dispatch({
        type: types.GET_BRANDS_BY_PARTNER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: types.GET_BRANDS_BY_PARTNER_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    });
};
